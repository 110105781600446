.App {
  text-align: left;
  -webkit-font-smoothing: antialiased;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

#hoverPopup {
  opacity: 0;
}

#hoverBase:hover ~ #hoverPopup {
  opacity: 1;
  position: relative;
  z-index: 5;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.google-login {
  color: white;
  background: none !important;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  /*border is optional*/
  cursor: pointer;
}
